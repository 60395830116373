<template>
  <ion-page>
    <ion-header mode="ios">
      <ion-toolbar>
        <ion-title>Address</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list class="mx-4">
        <div class="address-form mb-2" v-for="(data, index) in customerDetails.addresses" :key="index">
          <div class="address" @click="handleOpenModalUpdateAddress(data)">
            <div class="titles">
              <ion-text>{{ $t('customerPage.address') }} {{ index + 1 }}</ion-text>
            </div>
            <div class="contents mb-1">
              <ion-text>{{ getAddressFormat(data) }}</ion-text>
              <ion-text v-if="data.unit || data.floor_number"
                >{{ data.unit }}{{ data.unit ? ',' : ''
                }}{{ data.unit ? ' ' + data.floor_number : data.floor_number }}</ion-text
              >
              <ion-text v-if="data.city || data.postal_code"
                >{{ data.city }}{{ data.city ? ',' : ''
                }}{{ data.city ? ' ' + data.postal_code : data.postal_code }}</ion-text
              >
            </div>
          </div>
          <div class="btn-as-default">
            <ion-button mode="md" size="small" fill="outline" @click="$emit('handleSendAddress', data)"
              >Select</ion-button
            >
          </div>
        </div>
      </ion-list>
    </ion-content>
    <ion-footer mode="ios" class="footer ion-no-border">
      <ion-toolbar> </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import { getAddressFormat } from '@/modules/b2b/services/libs/helper';

export default {
  setup() {
    return {
      getAddressFormat
    };
  },
  props: ['customerDetails']
};
</script>

<style lang="scss" scoped>
.footer {
  height: 10px !important;
}
.address-form {
  padding: 0px 16px;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 4px 4px;
  & > .address {
    & > .titles {
      width: 100%;
      display: flex;
      padding-top: 10px;
      justify-content: space-between;
      & > ion-text:nth-child(1) {
        color: #92949c;
        font-weight: 600;
        font-size: 12px;
        text-transform: uppercase;
      }
      & > ion-text:nth-child(2) {
        font-weight: 600;
        font-size: 12px;
        text-transform: uppercase;
      }
    }
    & > .contents {
      display: flex;
      flex-direction: column;
      & > ion-text {
        margin: 2px 0px;
        font-size: 16px;
        line-height: 22px;

        color: #181818;
      }
    }
  }
  & > .btn-as-default {
    display: flex;
    justify-content: flex-start;

    & > ion-button {
      text-transform: none;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 10px;
      width: fit-content;
    }
  }
}
</style>
