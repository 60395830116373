<template>
  <div class="form-payment">
    <div class="description">{{ $t('Optional') }}</div>
    <ion-button class="add-card" @click="handleOpenModalAddCard">
      <ion-icon :icon="cardOutline"></ion-icon>
      <div class="pl-1">{{ $t('newcustomer.add_debit') }}</div>
    </ion-button>
    <!-- <ion-button v-if="isShowNetBankCard" class="add-card">
      <ion-icon :icon="cardOutline"></ion-icon>
      <div class="pl-1">{{ $t('newcustomer.add_nets') }}</div>
    </ion-button> -->
    <!-- <div class="toggle-card">
      <div class="area">
        <ion-list lines="none">
          <ion-item>
            <ion-toggle mode="ios" class="pr-1" value="pepperoni"> </ion-toggle>
            <ion-text
              ><div class="label">
                Allow branches to use main <br />
                branch’s cards to make payment
              </div></ion-text
            >
          </ion-item></ion-list
        >
      </div>
    </div>
    <div class="label-description mt-2">
      <ion-text
        ><div>
          Enable to allow branches to use main <br />
          branch’s card for payment.
        </div></ion-text
      >
    </div> -->

    <div>
      <CardList
        :isOpenModalAddCard="isOpenModalAddCard"
        @handleCloseModalPayment="handleCloseModalPayment"
        @skip="$emit('skip')"
        @handleAddCard="handleEmitAddCard"
        :isEdit="false"
        :customerDetailsEdit="customerDetailsEdit"
        :isPaymentPage="isPaymentPage"
        :listCardSortDefaultProps="listCardSortDefault"
        :defaultCardProps="defaultCard"
      />
    </div>
  </div>
</template>

<script>
import { cardOutline } from 'ionicons/icons';
import CardList from '@/modules/b2b/views/account/Account/components/NewCardList.vue';

export default {
  components: {
    CardList
  },
  data() {
    return {
      isOpenModalAddCard: false,
      isPaymentPage: true,
      user: [],
      isShowNetBankCard: true
    };
  },
  inject: ['$storage'],
  props: ['customerDetailsEdit', 'listCardSortDefault', 'defaultCard'],
  setup() {
    return {
      cardOutline
    };
  },
  async created() {
    this.user = await this.$storage.getUser();
    if (this.user.country.id !== 193) this.isShowNetBankCard = false; //if user myamar disbale nets bank card
  },
  methods: {
    handleOpenModalAddCard() {
      this.isOpenModalAddCard = true;
      setTimeout(() => {
        this.isOpenModalAddCard = false;
      }, 300);
    },
    handleCloseModalPayment() {
      this.$emit('handleCloseModal');
    },
    handleEmitAddCard(listCard, defaultCard) {
      this.$emit('handleAddCard', listCard, defaultCard);
    }
  }
};
</script>

<style lang="scss" src="./styles/Payment.scss" scoped></style>
