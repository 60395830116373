<template>
  <div class="pb-6">
    <div class="title-description">{{ $t('Optional') }}</div>

    <div v-if="customerImage?.length || listImageTmp?.length" class="">
      <ion-grid>
        <ion-row class="px-3" v-if="customerImage.length > 0">
          <ion-col size="4" v-for="image in customerImage" :key="image.id">
            <image-company :image="image.image" />
          </ion-col>
        </ion-row>
        <ion-row class="px-3" v-else-if="listImageTmp.length > 0">
          <ion-col size="4" v-for="(image, index) in listImageTmp" :key="index">
            <ion-img :src="image.image"></ion-img>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <!-- <ion-button class="btn-add-image" @click="setOpenModalImage(true)">
      <ion-icon slot="icon-only" class="mr-2 fs-3" :icon="addCircleOutline" />
      {{ customerImage?.length ? $t('edit') : $t('add') }} {{ $t('customerPage.images') }}
    </ion-button> -->
    <ion-button class="form-upload" @click="setOpenModalImage(true)">
      <ion-icon :icon="addCircleOutline" />
      <ion-text
        ><div>{{ $t('newcustomer.upload_company_logo') }}</div></ion-text
      >
    </ion-button>
    <div class="btn-form">
      <ion-button fill="outline" class="fw-500" @click="$emit('skip')">
        {{ $t('back') }}
      </ion-button>
      <ion-button color="primary" class="fw-500" @click="$emit('save-success')">
        {{ $t('save') }}
      </ion-button>
    </div>
  </div>

  <!-- modal image -->
  <ion-modal :is-open="isOpenModalImageRef" css-class="" @didDismiss="setOpenModalImage(false)">
    <modal-image
      @close-modal="setOpenModalImage(false)"
      @save-image="saveImage"
      :customerImage="customerImage"
      :customerId="customerId"
      :listAddImageProps="listAddImage"
      :listDeleteImageProps="listDeleteImage"
    >
    </modal-image>
  </ion-modal>

  <!-- modal info -->
  <ion-modal
    :is-open="isOpenModalInfoRef"
    css-class="modal-info custom-modal"
    @didDismiss="setOpenModalInfo(false)"
  >
    <modal-content-info :title="modalInfoTitle" :content="modalInfoContent" @close-modal="closeModalInfo">
    </modal-content-info>
  </ion-modal>

  <!-- loading -->
  <ion-loading
    :is-open="isOpenLoadingref"
    @didDismiss="setOpenLoading(false)"
    cssClass="custom-loading"
    message=""
    spinner="crescent"
  >
  </ion-loading>
</template>

<script>
import ModalImage from '@/modules/b2b/views/customer/components/ModalImage.vue';
import ImageCompany from '@/modules/b2b/views/customer/components/ImageCompany.vue';
import ModalContentInfo from '@/modules/b2b/views/shared/ModalContentInfo.vue';

import { addCircleOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { ACTIONS } from '@/modules/b2b/store/customer/actions';

const { mapGetters, mapActions } = createNamespacedHelpers('b2b/customer');

export default defineComponent({
  name: 'ImageForm',
  components: {
    // user defined component
    ImageCompany,
    ModalImage,
    ModalContentInfo
  },
  data() {
    return {
      hasError: false,
      titleSuccess: this.$t('success'),
      titleFailed: this.$t('failed'),
      modalInfoTitle: '',
      modalInfoContent: '',
      contentSuccessAddImage: this.$t('customerPage.image_has_been_added'),
      contentSuccessUpdateImage: this.$t('customerPage.image_has_been_updated'),
      listImageTmp: []
    };
  },
  props: ['customerId', 'listAddImage', 'listDeleteImage'],
  setup() {
    // modal image
    const isOpenModalImageRef = ref(false);
    const setOpenModalImage = (state) => (isOpenModalImageRef.value = state);
    // modal info
    const isOpenModalInfoRef = ref(false);
    const setOpenModalInfo = (state) => (isOpenModalInfoRef.value = state);

    // loading
    const isOpenLoadingref = ref(false);
    const setOpenLoading = (state) => (isOpenLoadingref.value = state);
    return {
      addCircleOutline,
      // modal image
      isOpenModalImageRef,
      setOpenModalImage,
      // modal info
      isOpenModalInfoRef,
      setOpenModalInfo,
      // loading
      isOpenLoadingref,
      setOpenLoading
    };
  },
  computed: {
    ...mapGetters(['customerImage', 'status', 'error'])
  },
  mounted() {
    this[ACTIONS.RESET_CUSTOMER_IMAGE]();
  },
  emits: ['save-success'],
  methods: {
    ...mapActions([
      ACTIONS.ADD_CUSTOMER_IMAGE,
      ACTIONS.RESET_STATUS_ERROR,
      ACTIONS.UPDATE_CUSTOMER_IMAGE,
      ACTIONS.GET_CUSTOMER_IMAGE,
      ACTIONS.RESET_CUSTOMER_IMAGE
    ]),
    async saveImage(listAddImage, listDeleteImage) {
      if (this.customerId) {
        this.setOpenLoading(true);

        const addImageParams = {
          payload: listAddImage
        };
        await this[ACTIONS.ADD_CUSTOMER_IMAGE](addImageParams);
        const updateImageParams = {
          id: listDeleteImage
        };
        await this[ACTIONS.UPDATE_CUSTOMER_IMAGE](updateImageParams);
        this.setOpenLoading(false);
        // show modal info
        if (this.status) {
          this.modalInfoTitle = this.titleSuccess;
          this.modalInfoContent = this.customerImage?.length
            ? this.contentSuccessUpdateImage
            : this.contentSuccessAddImage;
          await this[ACTIONS.GET_CUSTOMER_IMAGE]({
            supplierId: this.customerId,
            active: true
          });
        } else {
          this.modalInfoTitle = this.titleFailed;
          this.modalInfoContent = this.error.message;
          this.hasError = true;
          this[ACTIONS.RESET_STATUS_ERROR]();
        }
        this.setOpenModalInfo(true);
      } else {
        await this.setOpenModalImage(false);
        this.listImageTmp = listAddImage;
        this.$emit('save-image-step-form', listAddImage, listDeleteImage);
      }
    },
    closeModalInfo() {
      this.setOpenModalInfo(false);
      if (this.hasError) {
        this.hasError = false;
        return;
      }
      setTimeout(() => {
        this.setOpenModalImage(false);
      }, 200);
    }
  }
});
</script>

<style src="./styles/ImageForm.scss" lang="scss" scoped></style>
