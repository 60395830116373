<template>
  <div class="step-content pb-4 overflow-hidden" v-if="isShow">
    <!-- step 1 - company information -->
    <div class="overflow-hidden">
      <div class="position-relative d-flex align-center py-3 px-5">
        <div :class="['form-icon first-step', step >= TEMPSTEP.CUSTOMER ? 'bg-orange' : 'bg-grey']">
          <ion-text>{{ TEMPSTEP.CUSTOMER }}</ion-text>
        </div>
        <ion-text
          class="ml-3"
          :class="step >= TEMPSTEP.CUSTOMER ? 'title-h2 fw-500' : ''"
          :color="step >= TEMPSTEP.CUSTOMER ? 'primary' : 'grey'"
        >
          {{ $t('customerPage.business_information') }}
        </ion-text>
      </div>
      <transition-expand>
        <div v-if="step === TEMPSTEP.CUSTOMER" class="step-item">
          <business-infomation
            :businessInformation="businessInformation"
            :addressRefInput="addressRefInput"
            :isBranch="isBranch"
            :customerDetails="customerDetails"
            :sameAsParent_business="sameAsParent_business"
            @handleDataBusinessInformation="handleDataBusinessInformation"
          />
        </div>
      </transition-expand>
    </div>

    <!-- step 2 - Delivery Hours -->
    <div class="overflow-hidden">
      <div class="position-relative d-flex align-center py-3 px-5">
        <div :class="['form-icon step', step >= TEMPSTEP.DELIVERY_HOURS ? 'bg-orange' : 'bg-grey']">
          <ion-text>{{ TEMPSTEP.DELIVERY_HOURS }}</ion-text>
        </div>
        <ion-text
          class="ml-3"
          :class="step >= TEMPSTEP.DELIVERY_HOURS ? 'title-h2 fw-500' : ''"
          :color="step >= TEMPSTEP.DELIVERY_HOURS ? 'primary' : 'grey'"
        >
          {{ $t('customerPage.delivery_hours') }}
        </ion-text>
      </div>
      <transition-expand>
        <div v-if="step === TEMPSTEP.DELIVERY_HOURS" class="step-item">
          <hours-form
            :isEdit="false"
            :workingHours="[]"
            :workingHoursBranch="customerDetails"
            :defaultWorkingHours="groupsAndCountriesAndDays.days"
            :headCompanyHourses="[]"
            :openTimeProps="openTime"
            :closeTimeProps="closeTime"
            :workingHoursProps="workingHourProps"
            :isBranch="isBranch"
            :sameAsParent_delivery="sameAsParent_delivery"
            @handleDataDeliveryHours="handleDataDeliveryHours"
            @failed="openModalFailed"
            @skip="backStep"
          />
        </div>
      </transition-expand>
    </div>
    <!-- step 3 - Add Card -->
    <div class="overflow-hidden" v-if="!isHiddenPaymentIndo">
      <div class="position-relative d-flex align-center py-3 px-5">
        <div :class="['form-icon step', step >= TEMPSTEP.ADD_CARD ? 'bg-orange' : 'bg-grey']">
          <ion-text>{{ TEMPSTEP.ADD_CARD }}</ion-text>
        </div>
        <ion-text
          class="ml-3"
          :class="step >= TEMPSTEP.ADD_CARD ? 'title-h2 fw-500' : ''"
          :color="step >= TEMPSTEP.ADD_CARD ? 'primary' : 'grey'"
        >
          {{ $t('connectSupplier.payment') }}
        </ion-text>
      </div>
      <transition-expand>
        <div v-if="step === TEMPSTEP.ADD_CARD" class="step-item">
          <Payment
            @skip="backStep"
            @handleAddCard="handleAddCard"
            :listCardSortDefault="listCardSortDefault"
            :defaultCard="defaultCard"
          />
        </div>
      </transition-expand>
    </div>

    <!-- step 4 - Image -->
    <div class="overflow-hidden">
      <div class="position-relative d-flex align-center py-3 px-5">
        <div :class="['form-icon last-step', step >= TEMPSTEP.IMAGES ? 'bg-orange' : 'bg-grey']">
          <ion-text>{{ TEMPSTEP.IMAGES }}</ion-text>
        </div>
        <ion-text
          class="ml-3"
          :class="step >= TEMPSTEP.IMAGES ? 'title-h2 fw-500' : ''"
          :color="step >= TEMPSTEP.IMAGES ? 'primary' : 'grey'"
        >
          {{ $t('customerPage.upload_company_logo') }}
        </ion-text>
      </div>

      <transition-expand>
        <div v-if="step === TEMPSTEP.IMAGES" class="step-item">
          <image-form
            @skip="backStep"
            @save-success="handleFinishForm"
            @save-image-step-form="handleDataSaveImage"
            :listAddImage="listAddImage"
            :listDeleteImage="listDeleteImage"
          />
        </div>
      </transition-expand>
    </div>
  </div>

  <!-- modal info -->
  <ion-modal
    :is-open="isOpenModalInfoRef"
    css-class="modal-info custom-modal"
    @didDismiss="setOpenModalInfo(false)"
  >
    <modal-content-info :title="modalInfoTitle" :content="modalInfoContent" @close-modal="closeModalInfo">
    </modal-content-info>
  </ion-modal>
  <ion-modal :is-open="isOpenModalCuisine" backdropDismiss="false">
    <CuisinePreferences
      :customerId="customerId"
      :countryId="countryId"
      :isNewCompany="true"
      @later="handleSelectionCuisine"
      @apply="handleSelectionCuisine"
    />
  </ion-modal>
  <!-- loading -->
  <ion-loading
    :is-open="isOpenLoadingref"
    @didDismiss="setOpenLoading(false)"
    cssClass="custom-loading"
    message=""
    spinner="crescent"
  >
  </ion-loading>
  <ion-loading
    :is-open="isOpenRefLoading"
    cssClass="my-custom-class"
    :message="$t('please_wait')"
    @didDismiss="setOpenLoadingDefault(false)"
  >
  </ion-loading>
</template>

<script>
import { apolloClient } from '@/main';
import { ACTIONS } from '@/modules/b2b/store/customer/actions';
import { ACTIONS as ACTIONS_PAYMENT } from '@/modules/b2b/store/payment/actions';
import BusinessInfomation from '@/modules/b2b/views/customer/components/BusinessInfomation/index.vue';
import HoursForm from '@/modules/b2b/views/customer/components/HoursForm.vue';
import ImageForm from '@/modules/b2b/views/customer/components/ImageForm.vue';
import Payment from '@/modules/b2b/views/customer/components/Payment.vue';
import ModalContentInfo from '@/modules/b2b/views/shared/ModalContentInfo.vue';
import TransitionExpand from '@/modules/b2b/views/shared/TransitionExpand.vue';
import Clevertap from '@/services/shared/helper/clevertap';
import { Device } from '@capacitor/device';
import { alertController } from '@ionic/vue';
import {
  checkmarkDoneOutline,
  createOutline,
  imageOutline,
  locationOutline,
  personOutline,
  timeOutline
} from 'ionicons/icons';
import { defineAsyncComponent, defineComponent, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { STEP } from './constants';
const { mapGetters: mapGettersPayment, mapActions: mapActionsPayment } = createNamespacedHelpers(
  'b2b/payment'
);

import {
  addCustomer,
  addCustomerImage,
  getCustomerDetailBuyer,
  hoursAdd,
  updateCustomerImage
} from '@/modules/b2b/services/graphql';
import { errorAlert } from '@/utils/alert';
const { mapActions, mapGetters } = createNamespacedHelpers('b2b/customer');
export default defineComponent({
  name: 'AddCustomer',
  components: {
    // User defined component
    BusinessInfomation,
    HoursForm,
    ImageForm,
    Payment,
    TransitionExpand,
    ModalContentInfo,
    CuisinePreferences: defineAsyncComponent(() =>
      import('@/modules/shared/views/cuisine-preferences/index.vue')
    )
  },
  data() {
    return {
      // modal info
      titleSuccess: this.$t('success'),
      titleFailed: this.$t('failed'),
      modalInfoTitle: '',
      modalInfoContent: '',
      contentSuccessPerson: this.$t('customerPage.person_information_saved'),
      contentSuccessAddress: this.$t('customerPage.address_saved'),
      contentSuccessDeliveryHours: this.$t('customerPage.delivery_hours_saved'),
      contentSuccessCompany: this.$t('customerPage.company_information_saved'),

      // common data
      step: STEP.CUSTOMER,
      headId: Number(this.$route.query.headId),
      user: null,
      hasError: false,
      customerId: null,
      //businessInformation
      businessInformation: [],
      addressRefInput: '',
      sameAsParent_business: false,
      //delivery hours
      deliveryHours: [],
      openTime: '',
      closeTime: '',
      workingHourProps: [],
      sameAsParent_delivery: false,
      //card form
      listCardSortDefault: [],
      defaultCard: '',
      //image form
      listAddImage: [],
      listDeleteImage: [],
      //
      isOpenRefLoading: false,
      customerDetails: [],
      TEMPSTEP: {},
      isShow: false,
      deviceId: '',
      countryId: null
    };
  },
  setup() {
    // modal info
    const isOpenModalInfoRef = ref(false);
    const setOpenModalInfo = (state) => (isOpenModalInfoRef.value = state);

    // loading
    const isOpenLoadingref = ref(false);
    const setOpenLoading = (state) => (isOpenLoadingref.value = state);
    //modal cuisine
    const isOpenModalCuisine = ref(false);
    const setOpenModalCuisine = (state) => (isOpenModalCuisine.value = state);
    return {
      // constant
      STEP,

      // icon
      createOutline,
      personOutline,
      locationOutline,
      timeOutline,
      imageOutline,
      checkmarkDoneOutline,

      // modal info
      isOpenModalInfoRef,
      setOpenModalInfo,
      // loading
      isOpenLoadingref,
      setOpenLoading,
      isOpenModalCuisine,
      setOpenModalCuisine,

      // Error
      errorAlert
    };
  },

  inject: ['$storage'],
  async mounted() {
    this[ACTIONS.GET_GROUPS_AND_COUNTRIES_AND_DAYS]();
    this[ACTIONS_PAYMENT.SET_STRIPE_ID](null);

    this.user = await this.$storage.getUser();
    this.countryId = this.user.country.id;
    this.handleGetCustomerDetail();
    await this.handleHidePaymentStep();
    this.isShow = true;

    if (this.user.country.id === 193) {
      this.deviceId = (await Device.getId()).uuid;
      this[ACTIONS_PAYMENT.GET_LIST_NETS_CARD](this.deviceId);
    }
  },
  computed: {
    ...mapGetters(['addCustomer', 'groupsAndCountriesAndDays', 'status', 'error', 'list']),
    ...mapGettersPayment(['stripeId']),
    isBranch() {
      return this.$route.query.headId ? true : false;
    },
    isHiddenPaymentIndo() {
      return true; // hide stripe payment for all user
    },
    requestNewCust() {
      return this.list.first_company_status === 'none' || this.list.first_company_status === 'pending';
    },
    isFirstCompany() {
      return this.list.first_company_status === 'none';
    }
  },
  methods: {
    ...mapActions([
      ACTIONS.HOURS_ADD,
      ACTIONS.GET_GROUPS_AND_COUNTRIES_AND_DAYS,
      ACTIONS.GET_HEAD_COMPANY,
      ACTIONS.ADD_BRANCH,
      ACTIONS.RESET_STATUS_ERROR,
      ACTIONS.GET_CUSTOMERS
    ]),
    ...mapActionsPayment([ACTIONS_PAYMENT.GET_LIST_NETS_CARD, ACTIONS_PAYMENT.SET_STRIPE_ID]),
    async handleHidePaymentStep() {
      if (!this.isHiddenPaymentIndo) {
        this.TEMPSTEP = this.STEP;
      } else {
        this.TEMPSTEP = {
          CUSTOMER: 1,
          DELIVERY_HOURS: 2,
          IMAGES: 3
        };
      }
    },
    async handleGetCustomerDetail() {
      try {
        if (this.isBranch) {
          const { data } = await apolloClient.query({
            query: getCustomerDetailBuyer,
            variables: {
              id: this.headId
            }
          });
          this.customerDetails = data.getCustomerDetailBuyer;
        }
      } catch (error) {
        this.errorAlert(error.message);
      }
    },
    setOpenLoadingDefault(params) {
      this.isOpenRefLoading = params;
    },
    handleDataBusinessInformation(params, address, sameAsParent) {
      this.businessInformation = params;
      this.addressRefInput = address;
      this.sameAsParent_business = sameAsParent;
      this.nextStep();
    },
    handleDataDeliveryHours(params, openTime, closeTime, workingHour, sameAsParent) {
      this.deliveryHours = params;
      this.openTime = openTime;
      this.closeTime = closeTime;
      this.workingHourProps = workingHour;
      this.sameAsParent_delivery = sameAsParent;
      this.nextStep();
    },
    handleAddCard(listCard, defaultCard) {
      this.listCardSortDefault = listCard;
      this.defaultCard = defaultCard;
      this.nextStep();
    },
    handleDataSaveImage(listAddImage, listDeleteImage) {
      this.listAddImage = listAddImage;
      this.listDeleteImage = listDeleteImage;
    },
    async handleAddCustomerIdListImage(params) {
      for (let i in this.listAddImage) {
        this.listAddImage[i].customer_id = params;
      }
    },
    async handleFinishForm() {
      Clevertap.onUserSuccessAddCompany(this.businessInformation);
      this.setOpenLoadingDefault(true);
      try {
        this.businessInformation = {
          ...this.businessInformation,
          main_customer_id: Number(this.$route.query.headId)
        };
        if (this.stripeId !== '') {
          this.businessInformation = {
            ...this.businessInformation,
            stripe_customer_id: this.stripeId,
            stripe_card_id: this.defaultCard
          };
        }
        if (this.isBranch) {
          this.businessInformation.credit_term = this.customerDetails.credit_term;
        }
        const { data } = await apolloClient.mutate({
          mutation: addCustomer,
          variables: {
            item: this.businessInformation
          }
        });
        this.customerId = data.addCustomer.id;
        const dataDeliveryTime = this.deliveryHours.map((item) => ({
          ...item,
          customer_id: Number(data.addCustomer.id)
        }));
        await apolloClient.mutate({
          mutation: hoursAdd,
          variables: {
            isDashboardPage: false,
            payload: dataDeliveryTime
          }
        });
        if (this.listAddImage?.length !== 0) {
          await this.handleAddCustomerIdListImage(this.customerId);
          await apolloClient.mutate({
            mutation: addCustomerImage,
            variables: {
              payload: this.listAddImage
            }
          });
          await apolloClient.mutate({
            mutation: updateCustomerImage,
            variables: {
              id: this.listDeleteImage
            }
          });
        }
        //call api get cuisine get customer id;
        this.setOpenLoadingDefault(false);
        this.setOpenModalCuisine(true);

        // set list company inreview
        this.$storage.setCompanyReviewList([data.addCustomer]);
      } catch (e) {
        this.setOpenLoadingDefault(false);
        this.presentAlert(e);
      }
    },
    handleChangePage() {
      this.setOpenModalCuisine(false);
      this.presentAlert(this.$t('we_received_registration'), this.$t('thank_you_first_company'));
      if (this.list.first_company_status === 'none' || this.list.first_company_status === 'pending') {
        this.$router.push({ name: 'select-customer' });
      } else {
        if (this.$route.query.headId) {
          this.$router.push(`/b2b/customers/${this.$route.query.headId}?tagged=true`);
        } else {
          this.$router.push(`/b2b/customers/${this.customerId}?tagged=true`);
        }
      }
    },
    handleSelectionCuisine() {
      this.handleChangePage();
    },

    async presentAlert(params, header = 'Alert') {
      const alert = await alertController.create({
        mode: 'ios',
        cssClass: 'my-custom-class',
        header,
        message: params,
        buttons: [
          {
            text: this.$t('OK')
          }
        ]
      });
      await alert.present();
    },
    getCustomerId(event) {
      this.customerId = event;
    },
    async savePerson(person) {
      const params = {
        item: {
          ...person,
          user_id: this.user.id,
          customer_id: this.customerId
        }
      };
      await this.saveData(ACTIONS.ADD_PERSON, params, this.contentSuccessPerson);
    },
    async saveAddress(address) {
      const params = {
        ...address,
        supplierId: this.customerId
      };
      await this.saveData(ACTIONS.ADD_ADDRESS, params, this.contentSuccessAddress);
    },
    async saveHours(payload) {
      const customerId = this.customerId;
      payload = payload.map((item) => ({ ...item, customer_id: customerId }));
      const variables = {
        isDashboardPage: false,
        payload
      };
      await this.saveData(ACTIONS.HOURS_ADD, { variables, customerId }, this.contentSuccessDeliveryHours);
    },
    saveImage() {
      this.openModalInfo(this.contentSuccessCompany);
    },
    async saveData(action, params, successMessage) {
      this.setOpenLoading(true);
      await this[action](params);
      this.setOpenLoading(false);
      this.openModalInfo(successMessage);
    },
    backStep() {
      this.step = this.step - 1;
    },
    nextStep() {
      this.step = this.step + 1;
    },
    openModalInfo(messageSucces) {
      // show modal info
      if (this.status) {
        this.modalInfoTitle = this.titleSuccess;
        this.modalInfoContent = messageSucces;
      } else {
        this.modalInfoTitle = this.titleFailed;
        this.modalInfoContent = this.error.message;
        this.hasError = true;
        this[ACTIONS.RESET_STATUS_ERROR]();
      }
      this.setOpenModalInfo(true);
    },
    openModalFailed(errMsg) {
      this.modalInfoTitle = this.titleSuccess;
      this.modalInfoContent = errMsg;
      this.hasError = true;
      this.setOpenModalInfo(true);
    },

    closeModalInfo() {
      this.setOpenModalInfo(false);
      if (this.hasError) {
        this.hasError = false;
        return;
      }
      // last step
      if (this.step === this.TEMPSTEP.IMAGES) {
        this.$router.push(`/b2b/customers/${this.customerId}?tagged=true`);
      } else {
        setTimeout(() => this.nextStep(), 300);
      }
    }
  }
});
</script>

<style src="./style.scss" lang="scss" scoped></style>
