<template>
  <vee-form :validation-schema="addressSchema">
    <!-- map -->
    <div>
      <ion-grid class="ion-no-padding">
        <ion-list mode="ios" lines="none">
          <!-- StartCompanyName -->
          <div>
            <ion-item
              :class="[
                'pl-4',
                form.companyName.isError
                  ? 'ion-item-danger'
                  : !form.companyName.isError && form.companyName.value
                  ? 'ion-item-primary'
                  : ''
              ]"
            >
              <ion-label
                class="text-title"
                :color="
                  form.companyName.isError
                    ? 'danger'
                    : !form.companyName.isError && form.companyName.value
                    ? 'primary'
                    : 'tertiary'
                "
                position="stacked"
                >{{ $t('customerPage.company_name') }}</ion-label
              >
              <ion-input
                :placeholder="$t('customerPage.enter_new_company_name')"
                ref="companyName"
                type="text"
                v-model.trim="form.companyName.value"
                @ionInput="handleValidateForm(1)"
                enterkeyhint="done"
                @keyup.enter="onCloseKeyboard"
              ></ion-input>
            </ion-item>
            <ion-text v-if="form.companyName.isError">
              <div class="pl-4 py-1 fs-12px text-danger">
                {{
                  $t(
                    `${
                      form.companyName.value.length === 0
                        ? 'customerPage.company_name_cannot_be_empty'
                        : 4 > form.companyName.value.length
                        ? 'customerPage.company_name_must_be_at_least_4_characters'
                        : form.companyName.errorMsg
                        ? form.companyName.errorMsg
                        : ''
                    }`
                  )
                }}
              </div>
            </ion-text>
          </div>
          <!-- EndCompanyName -->

          <!-- StartCustomerProfile -->
          <div>
            <ion-item
              @click="setOpenModalProfile(true)"
              :class="[
                'pl-4 mt-2 set-height',
                form.profile.isError
                  ? 'ion-item-danger'
                  : !form.profile.isError && form.profile.value
                  ? 'ion-item-primary'
                  : ''
              ]"
            >
              <div class="select-profile">
                <ion-text
                  class="ion-mb text-title"
                  :color="
                    form.profile.isError
                      ? 'danger'
                      : !form.profile.isError && form.profile.value
                      ? 'primary'
                      : 'tertiary'
                  "
                >
                  {{ $t('profile') }}
                </ion-text>
                <div class="row mb-2">
                  <div v-if="form.profile.value === ''" class="profile-name off-profile">
                    {{ $t('customerPage.select_a_profile') }}
                  </div>
                  <div v-else class="profile-name">
                    {{ form.profile.value }}
                  </div>
                  <ion-icon class="caret-down" :icon="caretDown"></ion-icon>
                </div>
              </div>
            </ion-item>
            <ion-text>
              <div v-if="form.profile.isError" class="pl-4 py-1 fs-12px text-danger">
                {{ $t('customerPage.profile_cannot_be_empty') }}
              </div>
            </ion-text>
          </div>
          <!-- EndCustomerProfile -->

          <!-- input search -->
          <div class="ion-margin-top">
            <ion-item :class="['pl-4', addressCss.length !== 0 ? 'ion-item-primary' : '']" class="ion-wrap">
              <ion-label class="text-title fw-500" position="stacked">{{
                $t('customerPage.address')
              }}</ion-label>
              <ion-label
                class="text-title"
                :color="addressCss.length !== 0 ? 'primary' : 'tertiary'"
                position="stacked"
                >{{ $t('search') }} {{ $t('customerPage.address') }}</ion-label
              >
              <div class="btn-search mt-2">
                <input
                  type="text"
                  ref="searchInput"
                  :placeholder="$t('customerPage.enter_a_location')"
                  enterkeyhint="done"
                  @keyup.enter="onCloseKeyboard"
                />
                <!-- <ion-icon :icon="searchOutline"></ion-icon> -->
              </div>
            </ion-item>
          </div>

          <!-- gooogle map -->
          <div>
            <div class="mt-4" ref="mapDiv" style="width: 100%; height: 40vh" />
          </div>

          <!-- end gooogle map -->
          <ion-list class="mt-1" v-if="isBranch && customerDetails?.addresses?.length > 0">
            <ion-item>
              <ion-label class="text-title">{{ $t('newcustomer.same_as_parent_company') }}</ion-label>
              <ion-checkbox
                v-model="sameAsParent"
                :value="sameAsParent"
                @update:modelValue="handleOpenModalSameasParent"
                slot="start"
                class="ml-4"
              >
              </ion-checkbox>
            </ion-item>
          </ion-list>
          <div class="ion-margin-top">
            <ion-row>
              <!-- city -->
              <ion-col size="6" class="pr-1 pb-0">
                <ion-item
                  :class="[
                    'px-4',
                    form.country.isError
                      ? 'ion-item-danger'
                      : !form.country.isError && form.country.value
                      ? ''
                      : ''
                  ]"
                >
                  <ion-label
                    class="text-title"
                    :color="
                      form.country.isError
                        ? 'danger'
                        : !form.country.isError && form.country.value
                        ? ''
                        : 'tertiary'
                    "
                    position="stacked"
                  >
                    {{ $t('country') }}
                  </ion-label>
                  <ion-input
                    disabled
                    v-model.trim="form.country.value"
                    name="country"
                    :placeholder="$t('enter_country')"
                    type="text"
                    @ionInput="handleValidateForm(6)"
                    enterkeyhint="done"
                    @keyup.enter="onCloseKeyboard"
                  >
                  </ion-input>
                </ion-item>
              </ion-col>

              <!-- postal code -->
              <ion-col size="6" class="pl-1 pb-0">
                <ion-item
                  :class="[
                    'px-4',
                    form.state.isError ? 'ion-item-danger' : !form.state.isError && form.state.value ? '' : ''
                  ]"
                >
                  <ion-label
                    class="text-title"
                    :color="
                      form.state.isError
                        ? 'danger'
                        : !form.state.isError && form.state.value
                        ? 'primary'
                        : 'tertiary'
                    "
                    position="stacked"
                  >
                    {{ $t('state') }}
                  </ion-label>
                  <ion-input
                    v-model.trim="form.state.value"
                    name="state"
                    type="text"
                    :disabled="sameAsParent"
                    @ionInput="handleValidateForm(7)"
                    :placeholder="$t('enter_state')"
                    enterkeyhint="done"
                    @keyup.enter="onCloseKeyboard"
                  >
                  </ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="6">
                <ion-text>
                  <div v-if="form.country.isError" class="px-4 py-1 fs-12px text-danger">
                    {{ $t('address_form.country.required') }}
                  </div>
                </ion-text>
              </ion-col>
              <ion-col size="6">
                <ion-text>
                  <div v-if="form.state.isError" class="px-4 py-1 fs-12px text-danger">
                    {{ $t('address_form.state.required') }}
                  </div>
                </ion-text>
              </ion-col>
            </ion-row>
          </div>

          <!-- city and postalCode -->
          <div class="ion-margin-top">
            <ion-row>
              <!-- city -->
              <ion-col size="6" class="pr-1 pb-0">
                <ion-item
                  :class="[
                    'pl-4',
                    form.city.isError
                      ? 'ion-item-danger'
                      : !form.city.isError && form.city.value
                      ? 'ion-item-primary'
                      : ''
                  ]"
                >
                  <ion-label
                    class="text-title"
                    :color="
                      form.city.isError
                        ? 'danger'
                        : !form.city.isError && form.city.value
                        ? 'primary'
                        : 'tertiary'
                    "
                    position="stacked"
                  >
                    {{ $t('city') }}
                  </ion-label>
                  <ion-input
                    v-model.trim="form.city.value"
                    name="city"
                    :placeholder="$t('customerPage.enter_city')"
                    type="text"
                    @ionInput="handleValidateForm(3)"
                    :disabled="sameAsParent"
                    enterkeyhint="done"
                    @keyup.enter="onCloseKeyboard"
                  >
                  </ion-input>
                </ion-item>
              </ion-col>

              <!-- postal code -->
              <ion-col size="6" class="pl-1 pb-0">
                <ion-item
                  :class="[
                    'pl-4',
                    form.postalCode.isError
                      ? 'ion-item-danger'
                      : !form.postalCode.isError && form.postalCode.value
                      ? 'ion-item-primary'
                      : ''
                  ]"
                >
                  <ion-label
                    class="text-title"
                    :color="
                      form.postalCode.isError
                        ? 'danger'
                        : !form.postalCode.isError && form.postalCode.value
                        ? 'primary'
                        : 'tertiary'
                    "
                    position="stacked"
                  >
                    {{ $t('postal_code') }}
                  </ion-label>
                  <ion-input
                    v-model.trim="form.postalCode.value"
                    name="postalCode"
                    type="number"
                    inputmode="numeric"
                    @ionInput="handleValidateForm(4)"
                    :placeholder="$t('customerPage.enter_postal_code')"
                    :disabled="sameAsParent"
                    onkeydown="if(event.key==='.'){event.preventDefault();}"
                    oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
                    enterkeyhint="done"
                    @keyup.enter="onCloseKeyboard"
                  >
                  </ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="6">
                <ion-text>
                  <div v-if="form.city.isError" class="pl-4 py-1 pr-1 fs-12px text-danger">
                    {{ $t('address_form.city.required') }}
                  </div>
                </ion-text>
              </ion-col>
              <ion-col size="6">
                <ion-text>
                  <div v-if="form.postalCode.isError" class="pl-4 py-1 pl-1 fs-12px text-danger">
                    {{ $t('address_form.postal_code.required') }}
                  </div>
                </ion-text>
              </ion-col>
            </ion-row>
          </div>
          <!-- end city and postal code -->

          <!-- street-->
          <div class="ion-margin-top">
            <ion-item
              :class="[
                'pl-4',
                form.street.isError
                  ? 'ion-item-danger'
                  : !form.street.isError && form.street.value
                  ? 'ion-item-primary'
                  : ''
              ]"
            >
              <ion-label
                :color="
                  form.street.isError
                    ? 'danger'
                    : !form.street.isError && form.street.value
                    ? 'primary'
                    : 'tertiary'
                "
                class="text-title"
                position="stacked"
              >
                {{ $t('street') }}
              </ion-label>
              <ion-input
                v-model.trim="form.street.value"
                name="road"
                :placeholder="$t('customerPage.enter_street_address')"
                type="text"
                @ionInput="handleValidateForm(5)"
                :disabled="true"
                enterkeyhint="done"
                @keyup.enter="onCloseKeyboard"
              ></ion-input>
            </ion-item>
            <ion-text v-if="form.street.isError">
              <div class="pl-4 py-1 fs-12px text-danger">
                {{ $t('address_form.road.required') }}
              </div>
            </ion-text>
          </div>

          <!--end street-->
          <!-- building name  -->
          <div class="ion-margin-top">
            <ion-item :class="['pl-4', form.building.value?.length !== 0 ? 'ion-item-primary' : '']">
              <ion-label
                :color="form.building.value?.length !== 0 ? 'primary' : 'tertiary'"
                class="text-title"
                position="stacked"
              >
                {{ $t('building_name') }}
              </ion-label>
              <ion-input
                v-model="form.building.value"
                name="building"
                :placeholder="$t('customerPage.enter_building_name')"
                type="text"
                :disabled="sameAsParent"
                enterkeyhint="done"
                @keyup.enter="onCloseKeyboard"
              ></ion-input>
            </ion-item>
          </div>
          <!-- end building name  -->
          <!-- floor - unit -->
          <div class="ion-margin-top">
            <ion-row>
              <ion-col size="6" class="pr-1 pb-0">
                <ion-item
                  :class="['pl-4', form.floorNumber.value?.length !== 0 ? 'ion-item-primary' : '']"
                  class="fs-2 custom-item"
                >
                  <ion-label
                    :color="form.floorNumber.value?.length !== 0 ? 'primary' : 'tertiary'"
                    position="stacked"
                  >
                    {{ $t('floorNo') }}
                  </ion-label>
                  <ion-input
                    v-model.trim="form.floorNumber.value"
                    name="floorNumber"
                    :placeholder="$t('customerPage.enter_floor_no')"
                    type="text"
                    :disabled="sameAsParent"
                    enterkeyhint="done"
                    @keyup.enter="onCloseKeyboard"
                  ></ion-input>
                </ion-item>
              </ion-col>
              <ion-col size="6" class="pl-1 pb-0">
                <ion-item
                  :class="['pl-4', form.unit.value?.length !== 0 ? 'ion-item-primary' : '']"
                  class="fs-2 custom-item"
                >
                  <ion-label
                    :color="form.unit.value?.length !== 0 ? 'primary' : 'tertiary'"
                    position="stacked"
                    >{{ $t('unitNo') }}
                  </ion-label>
                  <ion-input
                    v-model.trim="form.unit.value"
                    name="unit"
                    :placeholder="$t('customerPage.enter_unit_no')"
                    type="text"
                    :disabled="sameAsParent"
                    enterkeyhint="done"
                    @keyup.enter="onCloseKeyboard"
                  ></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
          </div>
          <!--end floor - unit -->
          <!--start stall -->
          <div class="ion-margin-top">
            <ion-item :class="['pl-4', form.stall.value?.length !== 0 ? 'ion-item-primary' : '']">
              <ion-label :color="form.stall.value?.length !== 0 ? 'primary' : 'tertiary'" position="stacked">
                {{ $t('stallNo') }}
              </ion-label>
              <ion-input
                v-model.trim="form.stall.value"
                name="stall"
                :placeholder="$t('customerPage.enter_stall_no')"
                :disabled="sameAsParent"
                enterkeyhint="done"
                @keyup.enter="onCloseKeyboard"
              >
              </ion-input>
            </ion-item>
          </div>
          <!--end stall -->

          <!-- start additional options b2b -->
          <div class="ion-margin-top">
            <ion-item class="pl-4 ion-no-border">
              <ion-label position="stacked" color="tertiary" class="fw-500 fs-3">
                {{ $t('additional_options') }}
              </ion-label>
              <div class="d-flex btn-toggle">
                <ion-toggle :checked="form.halal" @ionChange="handleAdditional(1)"> </ion-toggle>
                <div class="btn-toggle-text">
                  <ion-item>
                    <ion-label>{{ $t('halal') }}</ion-label>
                  </ion-item>
                  <div>
                    <div class="text-description" v-if="form.halal === false">
                      {{ $t('halal_off') }}
                    </div>
                    <div class="text-description" v-if="form.halal === true">
                      {{ $t('halal_on') }}
                    </div>
                  </div>
                </div>
              </div>
            </ion-item>
          </div>
          <!-- end additional options -->
          <!-- start btn -->
          <div class="btn-form">
            <ion-button
              :disabled="isDisableBtn"
              mode="md"
              @click="handleAddBusinessInfomation"
              class="mt-5 fw-500"
              >{{ $t('next') }}</ion-button
            >
          </div>
          <!-- end btn -->
        </ion-list>
      </ion-grid>
    </div>
    <ion-modal
      mode="md"
      css-class="modal-add custom-modal"
      :is-open="isOpenModalSameAsParentAddress"
      @didDismiss="handleCloseModal"
    >
      <ModalSameAddress
        :customerDetails="customerDetails"
        @handleSendAddress="handleSameAsParentAddress($event)"
      />
    </ion-modal>
    <ion-modal
      ref="modal"
      :initial-breakpoint="1"
      :breakpoints="[0, 0.25, 0.5, 0.75, 1]"
      mode="ios"
      css-class="bottom-sheet-modal"
      :is-open="isOpenSelectProfile"
      @didDismiss="setOpenModalProfile(false)"
    >
      <CustomerProfile
        @closeModal="setOpenModalProfile(false)"
        @selectProfile="setBusinessProfile"
        :businessProfile="customerProfilesData"
        :defaultProfile="form.profile.id"
      />
    </ion-modal>
    <ion-loading
      :is-open="isOpenRefLoading"
      cssClass="my-custom-class"
      :message="$t('please_wait')"
      @didDismiss="setOpenLoading(false)"
    >
    </ion-loading>
  </vee-form>
</template>
<script>
/* eslint-disable no-undef */
import checkbox from '@/assets/images/checkbox.png';
import CustomerProfile from '@/components/customer-profile/CustomerProfile.vue';
import { apolloClient } from '@/main';
import { getCustomerProfiles } from '@/modules/b2b/services/graphql';
import { addressSchema, getDataFromPlace, setUpMap } from '@/modules/b2b/services/libs/address';
import { getAddressFormat } from '@/modules/b2b/services/libs/helper';
import { ACTIONS } from '@/modules/b2b/store/customer/actions';
import { Keyboard } from '@capacitor/keyboard';
import { Loader } from '@googlemaps/js-api-loader';
import { alertController } from '@ionic/vue';
import { caretDown, closeOutline, searchOutline } from 'ionicons/icons';
import debounce from 'lodash.debounce';
import { Form as VeeForm, useForm } from 'vee-validate';
import { defineComponent, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';
import '../styles/AddressForm.scss';
import '../styles/VueTelInput.scss';
import ModalSameAddress from './ModalSameAddress.vue';
const { mapActions } = createNamespacedHelpers('b2b/customer');
export default defineComponent({
  name: 'AddressForm',
  components: {
    VeeForm,
    ModalSameAddress,
    CustomerProfile
  },
  props: ['businessInformation', 'addressRefInput', 'customerDetails', 'isBranch', 'sameAsParent_business'],
  inject: ['$storage'],
  data() {
    return {
      isOpenRef: false,
      isOpenRefLoading: false,
      sameAsParent: false,
      dataCheckDuplicateCustomer: [],
      addressCss: '',
      customerProfilesData: [],
      form: {
        companyName: {
          isError: false,
          value: '',
          errorMsg: ''
        },
        profile: {
          isError: false,
          id: null,
          value: ''
        },
        country: {
          isError: false,
          value: ''
        },
        state: {
          isError: false,
          value: ''
        },
        city: {
          isError: false,
          value: ''
        },
        postalCode: {
          isError: false,
          value: ''
        },
        street: {
          isError: false,
          value: ''
        },
        building: {
          value: ''
        },
        floorNumber: {
          value: ''
        },
        unit: {
          value: ''
        },
        stall: {
          value: ''
        },
        halal: false,
        latlng: {
          value: ''
        }
      }
    };
  },
  setup() {
    const user = ref(null);
    const selectedCompany = ref(null);
    const selectCustomer = ref(null);
    const input = ref(null);
    const placeData = ref(null);
    const latlng = ref(null);
    const isOpenModalSameAsParentAddress = ref(false);
    const setOpenModalSameAsParentAddress = (state) => (isOpenModalSameAsParentAddress.value = state);
    const key = process.env.VUE_APP_GOOGLEMAPS_KEY;
    const loader = new Loader({ apiKey: key, libraries: ['places'] });
    // Create a form context with the validation schema
    const isSameAsParent = ref(false);
    const isFirstMouted = ref(true); //check if user back and is same as parent ===true it dont show modal
    const isOpenSelectProfile = ref(false);
    const { errors, validate, values: address } = useForm({
      validationSchema: addressSchema
    });
    const onCloseKeyboard = () => {
      Keyboard.hide();
    };
    return {
      onCloseKeyboard,
      user,
      selectedCompany,
      selectCustomer,
      getAddressFormat,
      loader,
      input,
      placeData,
      latlng,
      isOpenModalSameAsParentAddress,
      setOpenModalSameAsParentAddress,
      isOpenSelectProfile,
      // form info
      addressSchema,
      errors,
      validate,
      address,
      // icon
      searchOutline,
      closeOutline,
      caretDown,
      checkbox,
      isSameAsParent,
      isFirstMouted
    };
  },
  watch: {
    'form.profile.value'() {
      if (this.form.profile.value) {
        this.form.profile.isError = false;
      }
    },
    'form.companyName.value'() {
      const regex = /^[\p{L}\d\s$!()?.'"@&+%,-]+$/u;
      if (this.form.companyName.value && !regex.test(this.form.companyName.value)) {
        this.form.companyName.isError = true;
        this.form.companyName.errorMsg = `${this.$t('only_allowed_symbol')} $ ! ( ) ? - . ' " @ & + % ,`;
        //becuase it will auto remove the invalid symbol and char, so we need to give user time to see the error meesage
        setTimeout(() => {
          this.form.companyName.value = this.form.companyName.value.replace(
            /[^\p{L}\d\s$!()?.'"@&+%,-]+/gu,
            ''
          );
          this.form.companyName.isError = false;
          this.form.companyName.errorMsg = '';
        }, 2000);
      }
    }
  },
  async mounted() {
    this.handleLoadOldDataWhenUserBack();
    await this.loader.load();
    this.user = await this.$storage.getUser();
    this.selectedCompany = await this.$storage.getSelectedCompany();
    this.currentUserCountry = this.user.country;
    this.form.country.value = this.user.country.description;
    await this.handleGetCustomerProfile();
    await this.handleSetupMap();
  },
  computed: {
    isDisableBtn() {
      return !this.latlng;
    }
  },
  methods: {
    ...mapActions([ACTIONS.GET_CUSTOMER_PROFILE]),
    handleSameAsParentAddress(event) {
      const address = event;
      this.form.city.value = address.city;
      this.form.postalCode.value = address.postal_code;
      this.form.street.value = address.road;
      this.form.building.value = address.building;
      this.form.floorNumber.value = address.floor_number;
      this.form.unit.value = address.unit;
      this.form.stall.value = address.stall;
      this.form.state.value = address.state;
      this.form.latlng.value = address.latlng;
      this.latlng = address.latlng;
      //reset validate error
      this.form.city.isError = false;
      this.form.postalCode.isError = false;
      this.form.street.isError = false;
      this.form.state.isError = false;
      //
      this.isSameAsParent = true;
      this.setOpenModalSameAsParentAddress(false);
    },

    async handleGetCustomerProfile() {
      try {
        const { data } = await apolloClient.query({
          query: getCustomerProfiles
        });
        this.customerProfilesData = data.getCustomerProfiles;
      } catch (error) {
        console.log(error);
      }
    },
    async getBackProfile(profileId) {
      await this.handleGetCustomerProfile();
      for (let i = 0; i < this.customerProfilesData.length; i++) {
        if (this.customerProfilesData[i].id === profileId) {
          this.form.profile.value = this.customerProfilesData[i].name;
        }
      }
    },
    handleCloseModal() {
      if (!this.isSameAsParent) {
        this.sameAsParent = false;
      }
      this.setOpenModalSameAsParentAddress(false);
      this.isSameAsParent = false;
    },
    handleOpenModalSameasParent() {
      if (this.sameAsParent && this.isFirstMouted) {
        this.setOpenModalSameAsParentAddress(true);
      }
      this.isFirstMouted = true;
    },
    async handleSetupMap() {
      this.input = this.$refs.searchInput;
      setUpMap({
        defaultCenter: this.latlng,
        input: this.input,
        user: this.user,
        mapDiv: this.$refs.mapDiv,
        callback: this.getDataFromPlaceFn
      });
    },
    //when user click back it will auto input data
    handleLoadOldDataWhenUserBack() {
      if (this.businessInformation?.length !== 0) {
        const data = this.businessInformation;
        this.form.companyName.value = data.name;
        this.form.profile.value = this.getBackProfile(data.profile_id);
        this.form.profile.id = data.profile_id;
        this.form.state.value = data.address.state;
        this.form.city.value = data.address.city;
        this.form.postalCode.value = data.address.postal_code;
        this.form.street.value = data.address.road;
        this.form.building.value = data.address.building;
        this.form.floorNumber.value = data.address.floor_number;
        this.form.unit.value = data.address.unit;
        this.form.stall.value = data.address.stall;
        this.latlng = data.address.latlng;
        this.form.halal = data.halal_products;
        this.$refs.searchInput.value = this.addressRefInput;
        this.addressCss = this.addressRefInput;
        this.isFirstMouted = false;
        this.sameAsParent = this.sameAsParent_business;
      }
    },
    setOpen(params) {
      this.isOpenRef = params;
    },
    setOpenLoading(params) {
      this.isOpenRefLoading = params;
    },
    validateForm(params) {
      if (params === 1) {
        const lengthCompanyName = this.form.companyName.value.length;
        lengthCompanyName === 0 || lengthCompanyName < 4
          ? (this.form.companyName.isError = true)
          : (this.form.companyName.isError = false);
        const regexSpecialChar = /^[\p{L}\d\s$!()?.'"@&+%,-]+$/u;
        const validateText = regexSpecialChar.test(this.form.companyName.value);
        if (!validateText) {
          this.form.companyName.isError = true;
          this.form.companyName.errorMsg = `${this.$t('only_allowed_symbol')} $ ! ( ) ? - . ' " @ & + % ,`;
        }
      } else if (params === 2) {
        this.form.profile.value.length === 0
          ? (this.form.profile.isError = true)
          : (this.form.profile.isError = false);
      } else if (params === 3) {
        this.form.city.value.length === 0
          ? (this.form.city.isError = true)
          : (this.form.city.isError = false);
      } else if (params === 4) {
        const length = this.form.postalCode.value.length;
        length === 0
          ? (this.form.postalCode.isError = true)
          : length >= 5
          ? (this.form.postalCode.isError = false)
          : '';
      } else if (params === 7) {
        this.form.state.value.length === 0
          ? (this.form.state.isError = true)
          : (this.form.state.isError = false);
      } else {
        this.form.street.value.length === 0
          ? (this.form.street.isError = true)
          : (this.form.street.isError = false);
      }
    },
    handleAdditional(params) {
      if (params === 1) {
        this.form.halal = !this.form.halal;
      }
    },
    handleValidateForm: debounce(function (params) {
      this.validateForm(params);
    }, 1000),
    async handleAddBusinessInfomation() {
      if (!this.latlng) {
        return;
      } else {
        const data = [1, 2, 3, 4, 5, 7];
        for (let value of data) {
          this.validateForm(value);
        }
        let addBusinessInfomation = true;
        for (let i in this.form) {
          if (this.form[i].isError) {
            addBusinessInfomation = false;
            break;
          }
        }
        if (addBusinessInfomation) {
          const item = {
            name: this.form.companyName.value,
            profile_id: this.form.profile.id,
            tenant_id: this.user && this.user?.tenant && this.user?.tenant?.id, // Hard code for auto tagged to TreeDots tenant.
            customer_type_id: 2,
            halal_products: this.form.halal,
            address: {
              state: this.form.state.value,
              floor_number: this.form.floorNumber.value,
              road: this.form.street.value,
              building: this.form.building.value,
              unit: this.form.unit.value,
              stall: this.form.stall.value,
              city: this.form.city.value,
              postal_code: this.form.postalCode.value,
              latlng: this.latlng,
              country_id: this.user?.country?.id,
              address_type_id: 1
            }
          };
          const refValueInputAddress = this.$refs?.searchInput?.value;
          this.$emit('handleDataBusinessInformation', item, refValueInputAddress, this.sameAsParent);
        }
      }
    },
    getDataFromPlaceFn(place) {
      const data = getDataFromPlace(place);
      this.placeData = data;
      this.addressCss = this.$refs?.searchInput?.value;
      // this.state = data.state;
      this.form.state.value = data.state;
      this.form.city.value = data.city;
      this.form.postalCode.value = data.postalCode;
      this.form.street.value = data.road;
      this.latlng = data.latlng;
      //reset validate error
      this.form.state.isError = false;
      this.form.city.isError = false;
      this.form.postalCode.isError = false;
      this.form.street.isError = false;
    },
    onSearch() {
      if (this.input.value === '') {
        this.input.focus();
        return;
      }
      google.maps.event.trigger(this.input, 'focus', {});
      google.maps.event.trigger(this.input, 'keydown', {
        keyCode: 40, // arrow down
        stopPropagation: () => {}, // because these get called
        preventDefault: () => {}
      });
      google.maps.event.trigger(this.input, 'keydown', { keyCode: 13 }); // enter
    },

    //show message error
    async presentAlert(params) {
      const alert = await alertController.create({
        cssClass: 'my-custom-class',
        header: 'Alert',
        message: params,
        buttons: [this.$t('OK')]
      });
      await alert.present();
    },
    async setOpenModalProfile(params) {
      this.isOpenSelectProfile = params;
    },
    setBusinessProfile(selectedProfile) {
      this.form.profile.value = selectedProfile.name;
      this.form.profile.id = selectedProfile.id;
    }
  }
});
</script>
<style src="../styles/BusinessInfomation.scss" lang="scss" scoped></style>
